import {
  Button,
  MainNavigation,
  MainNavigationItem,
  SubNavigation,
  SubNavigationContent,
  SubNavigationGroup,
  SubNavigationHeader,
  SubNavigationItem,
  SubNavigationList,
  SubNavigationTitle,
  Link,
  MainNavigationProps
} from '@wfp/ui';
import { T, useT } from '@transifex/react';
import { useEnv, useOnlineStatus } from '../../../context';
import { OfflineBanner } from '../OfflineBanner';
import { useNavbar } from './Navbar.hooks';
import './Navbar.scss';
import { App, AppBaseHref } from '@partnerconnect-monorepo/types';
import { UserProfileDetails } from './UserProfileDetails';

export const Navbar = () => {
  const { onlineStatus } = useOnlineStatus();
  const { deployedOnRootPath, appName } = useEnv();
  const t = useT();
  const {
    setLocale,
    locale,
    languages,
    signinRedirect,
    canAccessInvoicing,
    canAccessPartnershipsAndFinancesUsers,
    canAccessReportingUsers,
    isUsersManagementAccessed,
    canAccessReporting,
    canAccessPartnershipOpportunities,
    canAccessSpotcheck,
    isPartnershipsModuleAccessed,
    isAuthenticated
  } = useNavbar();

  return (
    <>
      <MainNavigation
        id="navbar"
        mobilePageWidth="full"
        pageWidth="lg"
        logo={
          <div className="navbar navbar__link">
            <Link
              href={AppBaseHref.Home}
              onClick={(e) => {
                if (appName !== App.Home) {
                  e.preventDefault();
                  window.location.assign('/');
                }
              }}
            >
              <h3>
                <T _str="Partner Connect" _tag="navbar" />
              </h3>
            </Link>
          </div>
        }
      >
        {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          (props: MainNavigationProps) => (
            <>
              {isAuthenticated ? (
                deployedOnRootPath &&
                appName !== App.Home && (
                  <MainNavigationItem>
                    <Link
                      href="/"
                      className={`navbar__link--active ${
                        !onlineStatus ? 'offline-link' : ''
                      }`}
                    >
                      <T _str={appName} _tag="navbar" />
                    </Link>
                  </MainNavigationItem>
                )
              ) : (
                <MainNavigationItem>
                  <Button
                    small
                    kind="navigation"
                    onClick={() => signinRedirect()}
                  >
                    <T _str="Login" _tag="navbar" />
                  </Button>
                </MainNavigationItem>
              )}
              {canAccessReporting && (
                <MainNavigationItem>
                  <Link
                    href={AppBaseHref.Reporting}
                    className={`${
                      appName === App.Reporting && !isUsersManagementAccessed
                        ? 'navbar__link--active'
                        : ''
                    } ${!onlineStatus ? 'offline-link' : ''}`}
                    onClick={(e) => {
                      if (appName !== App.Reporting) {
                        e.preventDefault();
                        window.location.assign(AppBaseHref.Reporting);
                      }
                    }}
                  >
                    <T _str="Reports" _tag="navbar" />
                  </Link>
                </MainNavigationItem>
              )}
              {(canAccessPartnershipOpportunities || canAccessSpotcheck) && (
                <MainNavigationItem
                  className={`wfp--main-navigation__user sub-navigation-item ${
                    !onlineStatus ? 'offline-link' : ''
                  }`}
                  subNavigation={
                    <SubNavigation>
                      <SubNavigationContent>
                        <SubNavigationList>
                          {canAccessPartnershipOpportunities && (
                            <SubNavigationItem>
                              <Link
                                href={AppBaseHref.Fla}
                                onClick={(e) => {
                                  if (appName !== App.Fla) {
                                    e.preventDefault();
                                    window.location.assign(AppBaseHref.Fla);
                                  }
                                }}
                              >
                                <T _str="Opportunities" _tag="navbar" />
                              </Link>
                            </SubNavigationItem>
                          )}
                          {canAccessSpotcheck && (
                            <SubNavigationItem>
                              <Link
                                href={AppBaseHref.Spotcheck}
                                onClick={(e) => {
                                  if (appName !== App.Spotcheck) {
                                    e.preventDefault();
                                    window.location.assign(
                                      AppBaseHref.Spotcheck
                                    );
                                  }
                                }}
                              >
                                <T _str="Spot-checks" _tag="navbar" />
                              </Link>
                            </SubNavigationItem>
                          )}
                        </SubNavigationList>
                      </SubNavigationContent>
                    </SubNavigation>
                  }
                >
                  <Link
                    {...(isPartnershipsModuleAccessed &&
                      !isUsersManagementAccessed && {
                        className: 'navbar__link--active'
                      })}
                  >
                    <T _str="Partnerships" _tag="navbar" />
                  </Link>
                </MainNavigationItem>
              )}
              {canAccessInvoicing && (
                <MainNavigationItem>
                  <Link
                    href={AppBaseHref.Invoicing}
                    className={`${
                      appName === App.Invoicing ? 'navbar__link--active' : ''
                    } ${!onlineStatus ? 'offline-link' : ''}`}
                    onClick={(e) => {
                      if (appName !== App.Invoicing) {
                        e.preventDefault();
                        window.location.assign(AppBaseHref.Invoicing);
                      }
                    }}
                  >
                    <T _str="Finances" _tag="navbar" />
                  </Link>
                </MainNavigationItem>
              )}
              {(canAccessReportingUsers ||
                canAccessPartnershipsAndFinancesUsers) && (
                <MainNavigationItem
                  className="wfp--main-navigation__user sub-navigation-item"
                  subNavigation={
                    <SubNavigation>
                      <SubNavigationContent>
                        <SubNavigationList>
                          {canAccessReportingUsers && (
                            <SubNavigationItem>
                              <Link
                                href={`${AppBaseHref.Reporting}users/`}
                                onClick={(e) => {
                                  if (appName !== App.Reporting) {
                                    e.preventDefault();
                                    window.location.assign(
                                      `${AppBaseHref.Reporting}users/`
                                    );
                                  }
                                }}
                              >
                                <T _str="Reporting" />
                              </Link>
                            </SubNavigationItem>
                          )}
                          {canAccessPartnershipsAndFinancesUsers && (
                            <SubNavigationItem>
                              <Link
                                href={`${AppBaseHref.Fla}users/`}
                                onClick={(e) => {
                                  if (appName !== App.Fla) {
                                    e.preventDefault();
                                    window.location.assign(
                                      `${AppBaseHref.Fla}users/`
                                    );
                                  }
                                }}
                              >
                                <T _str="Partnerships, Finances" />
                              </Link>
                            </SubNavigationItem>
                          )}
                        </SubNavigationList>
                      </SubNavigationContent>
                    </SubNavigation>
                  }
                >
                  <Link
                    {...(isUsersManagementAccessed && {
                      className: 'navbar__link--active'
                    })}
                  >
                    <T _str="Users" _tag="navbar" />
                  </Link>
                </MainNavigationItem>
              )}
              {isAuthenticated && <UserProfileDetails {...props} />}
              <MainNavigationItem>
                <a href="/support">
                  <T _str="Help" />
                </a>
              </MainNavigationItem>
              {languages.length > 0 && (
                <MainNavigationItem
                  className="wfp--main-navigation__user"
                  data-testid="navbar-user-container"
                  subNavigation={
                    <SubNavigation>
                      <SubNavigationHeader>
                        <SubNavigationTitle>
                          {t('Choose language', { _tag: 'navbar' })}
                        </SubNavigationTitle>
                      </SubNavigationHeader>
                      <SubNavigationContent>
                        <SubNavigationList>
                          <SubNavigationGroup>
                            {languages?.map(
                              ({ code, localized_name }, languageIdx) => (
                                <SubNavigationItem key={languageIdx}>
                                  <Button
                                    kind="ghost"
                                    small
                                    onClick={() => setLocale(code)}
                                  >
                                    <T _str={localized_name} _tag="navbar" />
                                  </Button>
                                </SubNavigationItem>
                              )
                            )}
                          </SubNavigationGroup>
                        </SubNavigationList>
                      </SubNavigationContent>
                    </SubNavigation>
                  }
                >
                  <span className="locale">{locale}</span>
                </MainNavigationItem>
              )}
            </>
          )
        }
      </MainNavigation>
      {!onlineStatus && <OfflineBanner />}
    </>
  );
};
