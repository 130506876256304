import { UserProfile } from '@partnerconnect-monorepo/types';
import { t } from '@transifex/native';
import { T } from '@transifex/react';
import {
  MainNavigationItem,
  SubNavigation,
  SubNavigationHeader,
  SubNavigationTitle,
  SubNavigationContent,
  Button,
  Text,
  User
} from '@wfp/ui';

interface UserProfileDetailsViewProps {
  username: string;
  userProfile: UserProfile | null;
  email: string | undefined;
  reportingUserRoles?: string;
  flaUserRoles?: string;
  logout: () => Promise<void>;
}

export const UserProfileDetailsView = ({
  userProfile,
  username,
  email,
  flaUserRoles,
  reportingUserRoles,
  logout,
  ...otherProps
}: UserProfileDetailsViewProps) => (
  <MainNavigationItem
    {...otherProps}
    className="wfp--main-navigation__user sub-navigation-item"
    subNavigation={
      <SubNavigation data-testid="navbar-partner-user-expanded-container">
        {email && (
          <SubNavigationHeader>
            <SubNavigationTitle
              data-testid="navbar-welcome-user"
              className="sub-navigation-content-item"
            >
              <Text kind="p">
                <T _str="Email" />:
              </Text>
              <Text kind="h6">{email}</Text>
            </SubNavigationTitle>
          </SubNavigationHeader>
        )}
        {(userProfile?.allowedModules.canAccessReporting ||
          userProfile?.allowedModules.canAccessPartnerships) && (
          <>
            {userProfile.allowedModules.canAccessReporting && (
              <SubNavigationContent className="sub-navigation-content-container">
                <Text kind="h5" className="module-name">
                  <T _str="Reporting" />
                </Text>
                <div className="sub-navigation-content-item">
                  <Text kind="p">
                    <T _str="Role(s)" />:
                  </Text>
                  <Text kind="h6">{reportingUserRoles ?? '-'}</Text>
                </div>
                <div className="sub-navigation-content-item">
                  <Text kind="p">
                    <T _str="Country" />:
                  </Text>
                  <Text kind="h6">
                    {userProfile.reportingCountry?.label ?? '-'}
                  </Text>
                </div>
                <div className="sub-navigation-content-item">
                  <Text kind="p">
                    <T _str="Organization" />:
                  </Text>
                  <Text kind="h6">
                    {userProfile.reportingPartnerName ?? '-'}
                  </Text>
                </div>
              </SubNavigationContent>
            )}
            {userProfile.allowedModules.canAccessPartnerships && (
              <SubNavigationContent className="sub-navigation-content-container">
                <Text kind="h5" className="module-name">
                  <T _str="Partnerships, Finances" />
                </Text>
                <div className="sub-navigation-content-item">
                  <Text kind="p">
                    <T _str="Role(s)" />:
                  </Text>
                  <Text kind="h6">{flaUserRoles ?? '-'}</Text>
                </div>
                <div className="sub-navigation-content-item">
                  <Text kind="p">
                    <T _str="Country" />:
                  </Text>
                  <Text kind="h6">
                    {userProfile.partnershipsCountry?.name ?? '-'}
                  </Text>
                </div>
                <div className="sub-navigation-content-item">
                  <Text kind="p">
                    <T _str="Organization" />:
                  </Text>
                  <Text kind="h6">{userProfile.flaPartnerName ?? '-'}</Text>
                </div>
              </SubNavigationContent>
            )}
          </>
        )}
        <div className="sub-navigation-content-footer">
          <Button small onClick={logout} data-testid="navbar-logout-button">
            <T _str="Logout" _tag="navbar" />
          </Button>
          <Button
            small
            kind="secondary"
            onClick={() => window.location.assign('/preferences/')}
          >
            <T _str="Preferences" />
          </Button>
        </div>
      </SubNavigation>
    }
  >
    <User
      alt={username}
      name={t('Welcome, {username}', {
        username
      })}
      ellipsis
    />
  </MainNavigationItem>
);
