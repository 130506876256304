import { useState } from 'react';
import { PreferenceTab } from '../../constants';

export const usePreferences = () => {
  const [activeItem, setActiveItem] = useState(PreferenceTab.PersonalInfo);

  return {
    activeItem,
    setActiveItem
  };
};
