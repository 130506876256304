export enum App {
  Fla = 'Partnerships',
  Reporting = 'Reporting',
  Home = 'Home',
  Invoicing = 'Invoicing',
  Spotcheck = 'Spotcheck'
}

export enum AppBaseHref {
  Fla = '/partnerships/',
  Reporting = '/reporting/',
  Home = '/',
  Invoicing = '/invoicing/',
  Spotcheck = '/spot-checks/'
}

export enum Enviroment {
  LOCAL = 'local',
  DEV = 'dev',
  QA = 'qa',
  PRODUCTION = 'production'
}

export interface EnvironmentVariables {
  production: boolean;
  appName: App;
  baseUrl: string;
  publicUrl: string;
  environment: string;
  authUrl: string;
  logoutUrl: string;
  authenticationClientId: string;
  authRedirectUrl: string;
  authLogoutRedirectUrl: string;
  transifexToken: string;
  sentryDsn: string;
  reactAppBaseRoute: string;
  deployedOnRootPath?: boolean;
  supportedLanguages: string;
  enableSpotcheckModule: boolean;
  enableReportingOverviewCharts: boolean;
}
