import { act, render, RenderOptions } from '@testing-library/react';
import { PropsWithChildren, ReactElement } from 'react';
import { AuthProvider } from 'react-oidc-context';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter } from 'react-router-dom';
import {
  OnlineStatusProvider,
  SnackbarContextProvider,
  UserProfileProvider,
  RoutesContextProvider,
  ContextsProvider
} from '../context';
import { ComposeContexts } from '../core/ComposeProviders';
import { RoutesList } from '../core/RoutesList';
import { FlagsProvider } from 'flagged';
import { FeatureFlag } from '../constants';

const client = new QueryClient({
  defaultOptions: {
    queries: {
      refetchInterval: false,
      refetchIntervalInBackground: false,
      refetchOnWindowFocus: false,
      retryOnMount: false,
      retry: false
    }
  }
});

export const Providers = ({ children }: PropsWithChildren) => (
  <FlagsProvider
    features={{
      [FeatureFlag.SpotcheckModule]: true,
      [FeatureFlag.ReportingOverviewCharts]: true
    }}
  >
    <QueryClientProvider client={client}>
      <OnlineStatusProvider>
        <SnackbarContextProvider hideProgressBar>
          <AuthProvider>
            <UserProfileProvider>
              <BrowserRouter basename="/">
                <RoutesContextProvider>
                  <ContextsProvider>
                    <ComposeContexts>
                      {children}
                      <RoutesList />
                    </ComposeContexts>
                  </ContextsProvider>
                </RoutesContextProvider>
              </BrowserRouter>
            </UserProfileProvider>
          </AuthProvider>
        </SnackbarContextProvider>
      </OnlineStatusProvider>
    </QueryClientProvider>
  </FlagsProvider>
);

const renderWithProviders = (
  ui: ReactElement,
  options?: Omit<RenderOptions, 'wrapper'>
) => render(ui, { wrapper: Providers, ...options });

const renderWithAct = (
  ui: ReactElement,
  options?: Omit<RenderOptions, 'wrapper'>
) => act(() => renderWithProviders(ui, options));

const wrapperWithProviders = ({ children }: PropsWithChildren) => (
  <Providers>{children}</Providers>
);

export * from '@testing-library/react';
export { renderWithProviders as render, renderWithAct, wrapperWithProviders };
