import {
  isPlainObject,
  mapKeys,
  mapValues,
  snakeCase,
  camelCase,
  startCase
} from 'lodash';
import { PERMISSIONS } from '../../utils/permissions';
import {
  ActivityLocation,
  ErrorResponse
} from '@partnerconnect-monorepo/types';
import { GROUPS } from '../../utils/groups';
import { GENERIC_ERROR_DETAIL_KEY } from '../../constants';

export const mapKeysDeepLodash = (
  obj: any,
  cb: (v: any, k: any) => any,
  isRecursive?: boolean
): any => {
  if (!obj && !isRecursive) {
    return {};
  }

  if (!isRecursive) {
    if (
      typeof obj === 'string' ||
      typeof obj === 'number' ||
      typeof obj === 'boolean'
    ) {
      return {};
    }
  }

  if (Array.isArray(obj)) {
    return obj.map((item: any) => mapKeysDeepLodash(item, cb, true));
  }

  if (!isPlainObject(obj)) {
    return obj;
  }

  const result = mapKeys(obj, cb);

  return mapValues(result, (value: any) => mapKeysDeepLodash(value, cb, true));
};

export const mapToSnakeCase = (values: any) => {
  return mapKeysDeepLodash(values, (v: any, k: any) => snakeCase(k));
};

export const mapToCamelCase = (values: any) => {
  return mapKeysDeepLodash(values, (v: any, k: any) => camelCase(k));
};

export const mapListToOptions = (values: Array<string>) => {
  return values.map((each) => {
    return {
      value: each,
      label: each
    };
  });
};

export const getLocationBasedOnAdminBreakdown = (
  location: ActivityLocation
) => {
  if (!location.adminBreakdown2) {
    return location.adminBreakdown1;
  }
  return `${location.adminBreakdown2} (${location.adminBreakdown1})`;
};

export const getCurrentYear = () => {
  const currentDate = new Date();
  return currentDate.getFullYear();
};

export const isWfpUser = (
  roles: Array<{ value: string; label: string }> | undefined
) => {
  if (!roles || roles.length === 0) {
    return false;
  }
  return roles.some((role) => role.value.includes('wfp'));
};

export const hasPermission = (
  userPermissions: Array<string>,
  permissionCheck: string
) => {
  if (!userPermissions || !permissionCheck) {
    return false;
  }
  const match = PERMISSIONS[permissionCheck];
  if (!match) {
    return false;
  }
  return userPermissions.includes(match);
};

export const hasGroup = (userGroups: Array<string>, groupCheck: string) => {
  if (!userGroups || !groupCheck) {
    return false;
  }
  const match = GROUPS[groupCheck];
  if (!match) {
    return false;
  }
  return userGroups.includes(match);
};

export const parseErrors = (err: any) => {
  if (typeof err === 'string') {
    return [err];
  }
  // filter negative values
  const filtered = err.filter((each: any) => each);
  // reduce to a list of unique errors
  const reduced = filtered.reduce((acc: any, curr: any) => {
    const values = Object.values(curr).filter(
      (each: any) => !acc.includes(each)
    );
    return [...acc, ...values];
  }, []);
  return reduced;
};

export const parseDjangoErrors = (err: ErrorResponse | undefined) => {
  if (!err) return [];
  const errorDetails = err.error.details;
  return Object.keys(errorDetails).map((key) => {
    if (key === GENERIC_ERROR_DETAIL_KEY) {
      return errorDetails[key];
    } else {
      return `${startCase(key)}: ${errorDetails[key]}`;
    }
  });
};

export const getDateRangeString = (
  startDate: string,
  endDate: string,
  locale: string
) => {
  return `${new Date(startDate).toLocaleString(locale, {
    day: 'numeric',
    month: 'short'
  })} - ${new Date(endDate).toLocaleString(locale, {
    day: 'numeric',
    month: 'short'
  })}`;
};
