import { usePreferences } from './Preferences.hooks';
import { PreferencesView } from './PreferencesView';

export const Preferences = () => {
  const { activeItem, setActiveItem } = usePreferences();

  return (
    <PreferencesView activeItem={activeItem} setActiveItem={setActiveItem} />
  );
};
