import { t } from '@transifex/native';

export const OPERATION_TYPES = [
  { value: 0, label: t('Limited Emergency Operation') },
  { value: 1, label: t('Country Strategic Plan') },
  { value: 2, label: t('Interim Country Strategic Plan') },
  { value: 3, label: t('Transitional Interim Country Strategic Plan') }
];

export const SPECIAL_CONDITIONS = [
  { value: 0, label: t('Food Distribution [FLA 2024.Feb.EN]') },
  { value: 1, label: t('Cash Distribution Activities [FLA 2024 Feb.EN]') },
  { value: 2, label: t('Vouchers Monetization [FLA 2024.Feb.EN]') },
  { value: 3, label: t('Additional Special Conditions (Annex 1)') },
  { value: 4, label: t('Other') }
];

export const MODALITIES = [
  t('Food'),
  t('Cash'),
  t('Capacity strengthening'),
  t('Technical and specialist services')
];
