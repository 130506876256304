import { useUserProfile } from '../../../../context';
import {
  getFlaUserRoleOptions,
  getReportingUserRoleOptions,
  getRolesLabel
} from '../../../../utils';
import { useMemo } from 'react';
import { useAuth } from 'react-oidc-context';

export const useUserProfileDetails = () => {
  const { userProfile, username } = useUserProfile();

  const reportingUserRoles = useMemo(
    () =>
      getRolesLabel(getReportingUserRoleOptions(userProfile?.reportingRoles)) ||
      undefined,
    [userProfile]
  );

  const flaUserRoles = useMemo(
    () =>
      getRolesLabel(getFlaUserRoleOptions(userProfile?.flaRoles)) || undefined,
    [userProfile]
  );

  const { signoutRedirect, user } = useAuth();

  const logout = () => signoutRedirect({ id_token_hint: user?.id_token });

  const email = userProfile?.email ?? user?.profile.email;

  return {
    userProfile,
    username,
    reportingUserRoles,
    flaUserRoles,
    logout,
    email
  };
};
