import { useEnv, useUserProfile } from '../../../context';
import { useCurrentLocale, useSigninRedirect } from '../../../hooks';
import { useFeature } from 'flagged';
import { useMemo } from 'react';
import { hasPermission } from '../../../helpers';
import { Permission } from '../../../utils';
import { useLocation } from 'react-router-dom';
import { FeatureFlag } from '../../../constants';
import { App } from '@partnerconnect-monorepo/types';
import { useAuth } from 'react-oidc-context';

export const useNavbar = () => {
  const { signinRedirect } = useSigninRedirect();

  const { userProfile } = useUserProfile();

  const { isAuthenticated } = useAuth();

  const { locale, languages, setLocale } = useCurrentLocale();

  const canAccessInvoicing = useMemo(
    () =>
      hasPermission(
        userProfile?.permissions ?? [],
        Permission.CanAccessInvoicing
      ),
    [userProfile?.permissions]
  );

  const canAccessPartnershipsAndFinancesUsers = useMemo(
    () =>
      hasPermission(
        userProfile?.permissions ?? [],
        Permission.CanAccessPartnershipsUsers
      ),
    [userProfile?.permissions]
  );

  const location = useLocation();

  const isUsersManagementAccessed = useMemo(
    () =>
      location.pathname.includes('/users') ||
      location.pathname.includes('/partner-users'),
    [location]
  );

  const canAccessReportingUsers = useMemo(
    () =>
      hasPermission(
        userProfile?.permissions ?? [],
        Permission.CanViewPartnerProfiles
      ),
    [userProfile?.permissions]
  );

  const canAccessReporting = useMemo(
    () =>
      hasPermission(
        userProfile?.permissions ?? [],
        Permission.CanViewMonthlyReports
      ),
    [userProfile?.permissions]
  );

  const canAccessPartnershipOpportunities = useMemo(
    () =>
      hasPermission(
        userProfile?.permissions ?? [],
        Permission.CanViewPartnershipOpportunity
      ),
    [userProfile?.permissions]
  );

  const canAccessSpotcheck =
    !!useFeature(FeatureFlag.SpotcheckModule) &&
    hasPermission(
      userProfile?.permissions ?? [],
      Permission.CanAccessSpotcheck
    );

  const { appName } = useEnv();

  const isPartnershipsModuleAccessed = [App.Fla, App.Spotcheck].includes(
    appName
  );

  return {
    setLocale,
    locale,
    languages,
    signinRedirect,
    canAccessInvoicing,
    canAccessPartnershipsAndFinancesUsers,
    canAccessReportingUsers,
    isUsersManagementAccessed,
    canAccessReporting,
    canAccessPartnershipOpportunities,
    canAccessSpotcheck,
    isPartnershipsModuleAccessed,
    isAuthenticated
  };
};
